// angular core
import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, Inject } from '@angular/core';
import { HttpResponse} from '@angular/common/http';
import { interval } from 'rxjs';

// Services
import { NgxSpinnerService } from 'ngx-spinner';

// third parties
import { SimpleGlobal } from 'ng2-simple-global'; // A simple global variable service for Angular2/4

// config
import { global_IS_LOCALDEV } from './app-config';
import { WINDOW } from './services/windows.service';


@Component({

  selector: 'app-root',
  // template: '',
  templateUrl: './app.component.html',
  // styles: [''],
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})

export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  loadingRequests: number; // data load requests queue


  constructor(

      @Inject(WINDOW) private window,

      public APP: SimpleGlobal,
      private spinner: NgxSpinnerService
  ) {

    function check_if_mobile() {
      let check = false;
      (function(a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
          check = true;
        }
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    }
    this.APP['isMobile'] = check_if_mobile();

    this.APP['loading-spinner'].request_to_hide = false;
    this.APP['loading-spinner'].minimum_delay_expired = false;
    this.spinner.show('loading_spinner', this.APP['loading-spinner']); // Show busy spinner

    // Implement a minimum showing delay for the spinner
    setTimeout(() => {
      if (this.APP['loading-spinner'].request_to_hide) this.spinner.hide('loading_spinner'); // Hide busy spinner
      else { this.APP['loading-spinner'].minimum_delay_expired = true; } // spinner will be hidden at a latter stage from some logic
    }, 1000);

  }


  /*** Load the critical data that are mandatory before the app can be used ***/
  private load_critical_data() {

    this.load_data();
  }

  private load_data() {

    this.loadingRequests = 1;

    this.check_dataLoad();
  }

  /*** End of data loading mechanism ***/
  private check_dataLoad() {

    this.loadingRequests = this.loadingRequests - 1;
    if (this.loadingRequests <= 0) {
      // all data were loaded successfully

      if (this.APP['loading-spinner'].minimum_delay_expired) this.spinner.hide('loading_spinner'); // Hide busy spinner
      else { this.APP['loading-spinner'].request_to_hide = true; } // spinner will be hidden after minimum showing delay is expired

      this.APP['isBusy'] = false; // unfreeze the DOM
      this.APP['criticaldataLoaded'] = true; // says that all data were loaded
      // console.log('Critical data loaded.');
    }
  }


  ngOnInit() {

    this.loadingRequests = 0;

    if (!global_IS_LOCALDEV) {
      // Live mode

      this.load_critical_data();

    } else {
      // Local dev mode

      this.check_dataLoad();
      console.log('Local dev mode');
    }
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {

  }

}
